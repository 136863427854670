import { UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, Typography, Row, Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { participantLoginThunkAction } from "../../store/participant/participant-thunk-action";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useToast from "../../hooks/useToast";


const ParticipantLogin = () => {

    const isLoggedIn = useSelector(state => state.participantSlice.participantAuth.isLoggedIn)
    const participantFailReason = useSelector(state => state.participantSlice.participantAuth.participantFailReason)

    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {contextHolder, showErrorToast} = useToast()

    useEffect(() => {
        if(isLoggedIn) {
            navigate(`/participant/participant-date-selection/${searchParams.get("plan")}`)
        }
    }, [isLoggedIn])

    useEffect(() => {
        if(participantFailReason) {
            showErrorToast("Hata", "Lütfen E-posta adresinizin Arbis sistemine kayıtlı olduğundan emin olun !")
        }
    }, [participantFailReason])

    const onFinish = async (values) => {
       await dispatch(participantLoginThunkAction({ email: values.email, plan_link: searchParams.get("plan") }));
    }

    return (
        <Row align="middle" justify="center" style={{ height: "100vh", flexDirection: "column" }}>
            {contextHolder}
            <Typography.Title level={3}>Toplan.in'e Hoş Geldiniz.</Typography.Title>
            <Typography.Title level={4}>Lütfen Arbis'e Kayıtlı E-Posta adresinizi girin.</Typography.Title>
            {searchParams.get("reason") ? <Alert closable type="error" message={"Giriş Yapmalısınız."}/> : null}
            <br />
            <Form
                name="normal_login"
                className="participant-login-form"
                initialValues={{ remember: true }}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}
                onFinish={onFinish}>

                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Lütfen e-posta adresinizi giriniz.',
                        }
                    ]}>
                    <Input
                        size='large'
                        width={500}
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="Kurumsal E-Posta adresiniz."
                    />
                </Form.Item>

                <Form.Item>
                    <br />
                    <Button block size="large" type="primary" htmlType="submit" className="login-form-button">
                        Giriş Yap
                    </Button>
                </Form.Item>
            </Form>
        </Row>
    )
}

export default ParticipantLogin;