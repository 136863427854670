import { createSlice } from "@reduxjs/toolkit";


const systemManagementSlice = createSlice({
    name: 'systemManagementSlice',
    initialState: {
        isAllModulesAreReady: false,
        moduleHealths: {
            user: false,
            onboarding: false,
            plan: false,
            participant: false,
        },
        isSystemLoading: true
    },

    reducers: {
        setModuleHealth: (state, { payload }) => {
            const { moduleName, health } = payload;
            state.moduleHealths[moduleName] = health;
        },

        setIsAllModulesAreReady: (state, { payload }) => {
            state.isAllModulesAreReady = payload;
        }
    }
})

export const { setModuleHealth, setIsAllModulesAreReady } = systemManagementSlice.actions;

export default systemManagementSlice.reducer;