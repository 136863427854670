import { Layout, Typography } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import TopLoader from "react-top-loader";
import TPMenu from "../tp-menu/tp-menu";
import TPPageLodaer from "../tp-loader/tp-page-lodaer";

export default function MainLayout({ children, isLoading, skeleton, menuItems, isFullPageLoading }) {

    return (
        <Layout className="layout" style={{ height: "100vh" }}>
            <Header style={{
                top: 0,
                zIndex: 1,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: "#fff",
                justifyContent: "space-between"
            }}>
                <TPMenu menuItems={menuItems} />
                <Typography
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        localStorage.removeItem("toplan_auth_token");
                        document.location.reload();
                    }} >
                    Çıkış Yap
                </Typography>
            </Header>
            {isLoading ? <TopLoader color="#1677ff" show fixed={false} duration={1000} /> : null}
            <Content style={{ padding: '20px 50px' }}>
                {isFullPageLoading ? <TPPageLodaer /> : isLoading ? skeleton : children}
            </Content>
            <Footer />
        </Layout>
    )
}

MainLayout.defaultProps = {
    menuItems: [
        { label: "Ana Sayfa", to: "/" },
        { label: "Plan Oluştur", to: "/create-plan" }
    ]
}