import { createAsyncThunk } from "@reduxjs/toolkit"
import { STORAGE_KEYS } from "../../constants/common-constants";
import { apiGet, apiPost } from "../../helpers/api"


/**
 * Kullanıcı login işlemi
 */
export const loginThunkAction = createAsyncThunk(
    'auth/login',
    async ({ email, password }, { getState, rejectWithValue, fulfillWithValue }) => {
        try {
            const loginRequest = await apiPost('/auth/login', { email, password });
            return fulfillWithValue(loginRequest);
        } catch (e) {
            rejectWithValue('LOGIN_FAILED');
            throw new Error(JSON.stringify(e));
        }
    }
)

/**
 * Kullanıcı logout işlemi
 * 
 * @returns {void}
 */
export const logoutThunkAction = () => createAsyncThunk(
    "auth/logout",
    async () => {
        try {
            localStorage.removeItem(STORAGE_KEYS.token);
            Promise.resolve(true)
        } catch (e) {
            Promise.reject(e);
        }
    }
)

export const getUserInformationsThunkAction = createAsyncThunk(
    'user/me',
    async (_, { fulfillWithValue, rejectWithValue }) => {
        try {
            const userInfo = await apiGet('/user/me');

            return fulfillWithValue(userInfo)
        } catch (e) {
            console.error('user/me', e);
            return rejectWithValue([])
        }
    }
)