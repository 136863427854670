import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiGet } from "../../helpers/api";


export const getAllResponsible = createAsyncThunk(
    'responsible/getAll',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await apiGet('/responsible');
            return fulfillWithValue(response.content);
        } catch (e) {
            console.log("getAllResponsible-error", e)
        }
    }
)