import { Steps, Row } from "antd";
import MainLayout from "../../../../components/layouts/main-layout";
import PlanForm from "./plan-form";
import { FormOutlined, ScheduleOutlined, UsergroupAddOutlined, ContainerOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useLayoutEffect, useState } from "react";
import SelectDate from "./select-date";
import AddParticipants from "./add-participants";
import Summary from "./summary";
import PlanResult from "./plan-result";
import { PLAN_TYPES } from "../../../../constants/common-constants";
import PlanType from "../plan-type";
import PanelType from "../panel-type";
import PanelSelection from "../panel-selection";
import { resetOnboardFields, setCurrentStep } from "../../../../store/onboard/onboard-slice";

const allSteps = [
    {
        title: 'Plan Türü',
        key: 'plan_type',
        isShow: true,
        content: <PlanType />,
        icon: <FormOutlined />
    },
    {
        title: 'Panel Türü',
        key: 'panel_type',
        isShow: false,
        content: <PanelType />,
        icon: <FormOutlined />
    },
    {
        title: 'Panel Seçimi',
        key: 'panel_selection',
        isShow: false,
        content: <PanelSelection />,
        icon: <FormOutlined />
    },
    {
        title: 'Genel Bilgiler',
        key: 'general_information',
        isShow: true,
        content: <PlanForm />,
        icon: <FormOutlined />
    },
    {
        title: 'Tarih Seçimi',
        key: 'select_date',
        isShow: true,
        content: <SelectDate />,
        icon: <ScheduleOutlined />
    },
    {
        title: 'Katılımcılar',
        key: 'participants',
        isShow: true,
        content: <AddParticipants />,
        icon: <UsergroupAddOutlined />
    },
    {
        title: 'Özet',
        key: 'summary',
        isShow: true,
        content: <Summary />,
        icon: <ContainerOutlined />
    },
    {
        title: 'Sonuç',
        key: 'result',
        isShow: true,
        content: <PlanResult />,
        icon: <CheckCircleOutlined />
    },
];

const ManageSteps = () => {

    // local states
    const [steps, setSteps] = useState([...allSteps]);

    // redux
    const dispatch = useDispatch()
    const currentStep = useSelector(state => state.onboardSlice.currentStep);
    const selectedPlanType = useSelector(state => state.onboardSlice.onboardFormData.plan.plan_type)

    // constants
    const items = steps.filter(step => !!step.isShow).map((item) => ({
        key: item.title,
        title: item.title,
        icon: item.icon,
        content: item.content
    }));

    useEffect(() => {
        if (selectedPlanType === PLAN_TYPES.MEETING) {
            setSteps(prev => {
                prev = prev.map(stepItem => {
                    if (["panel_type", "panel_selection"].includes(stepItem.key)) {
                        return {
                            ...stepItem,
                            isShow: false
                        }
                    }
                    return stepItem;
                })
                return prev;
            })
        } else if (selectedPlanType === PLAN_TYPES.PANEL) {
            setSteps(prev => prev.map(step => ({ ...step, isShow: true })))
        }
    }, [selectedPlanType])

    useEffect(() => {
        // ! component ile ilişki kesildiğinde formu resetlesin.
        return () => {
            dispatch(resetOnboardFields())
        }
    }, [])

    useLayoutEffect(() => {
        window.onbeforeunload = function () {
            return 'Yenilemek istiyor musunuz ?'
        };

        return () => {
            window.onbeforeunload = undefined;
            dispatch(setCurrentStep(0))
        }
    }, [])

    return (
        <MainLayout>
            <Row style={{ margin: '30px 100px 50px 100px' }}>
                <Steps labelPlacement="vertical" current={currentStep} items={items} />
            </Row>
            <div>{items[currentStep].content}</div>
        </MainLayout>
    )
}

export default ManageSteps;