import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsAllModulesAreReady } from "./system-management-slice";

const SystemManagementDataBridge = () => {

    const dispatch = useDispatch();
    const { moduleHealths } = useSelector(state => state.systemManagementSlice);


    useEffect(() => {
        
        if(!moduleHealths) {
            return;
        }
        const constraint = Object.values(moduleHealths).every(moduleHealth => moduleHealth === true);
        /**
         * isAllModulesAreReady keyinin default değeri false 
         * kontrol edilen değer false ise 
         * tekrar tekrar setleme işlemi yapmaması için if ile kontrol edildi.
         */
        if (constraint) {
            dispatch(setIsAllModulesAreReady(constraint))
        }
    }, [moduleHealths])


}

export default SystemManagementDataBridge;