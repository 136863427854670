import { useMemo, useRef } from 'react';
import { Button, Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { decreaseStep, increaseStep, setPlanDates } from '../../../../store/onboard/onboard-slice';
import useToast from '../../../../hooks/useToast';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css'
import TPCalendar from '../../../../components/tp-calendar/tp-calendar';

const handleDateClick = (arg) => {
    console.log(arg)
}

const SelectDate = () => {

    // hooks
    const { contextHolder, showToast } = useToast();

    // ref
    const calendar = useRef(null);

    // redux
    const selectedDates = useSelector(state => state.onboardSlice.onboardFormData.planDates)
    const dispatch = useDispatch();

    /**
     * Eğer hali hazırda seçili tarihler var ise onlar gelsin. 
     */
    const generatedEvents = useMemo(() => {
        if (selectedDates.length > 0) {
            return selectedDates.map(({ start_date, end_date, is_all_day }, i) => ({ id: i, start: start_date, end: end_date, allDay: is_all_day }))
        }
        return []
    }, [JSON.stringify(selectedDates)])


    /**
     * Geri butonuna tıklandığında tetiklenir.
     */
    const handlePrevButton = () => {
        dispatch(decreaseStep())
    };


    /**
     * Eklenen bir event'i kaldırmak için kullanılır.
     * 
     * @param {any} selectedEvent 
     */
    const remove = (selectedEvent) => {
        selectedEvent.event.remove();
    }


    /**
     * Tarih seçim işlevinden sonra tetiklenir.
     * 
     * @param {any} dateInfo 
     */
    const handleSelection = (dateInfo) => {
        let calendarApi = dateInfo.view.calendar;

        const data = {
            id: calendarApi.getEvents().length,
            start: dateInfo.startStr,
            end: dateInfo.endStr,
            allDay: dateInfo.allDay
        };

        calendarApi.addEvent(data)
    }


    /**
     * İleri butonuna tıklandığında tetiklenir.
     */
    const next = () => {

        if (calendar.current.calendar.getEvents().length === 0) {
            showToast('Dikkat !', 'En az bir tarih seçimi yapmalısınız', 'warning');
            return;
        }

        const dateMap = calendar.current.calendar.getEvents().map(data => {
            const event = calendar.current.calendar.getEventById(data._def.publicId);
            return {
                start_date: event.start.getTime(),
                end_date: event.end.getTime(),
                start_date_str: event.startStr,
                end_date_str: event.endStr,
                is_all_day: event.allDay,
            }
        })

        dispatch(setPlanDates(dateMap));

        dispatch(increaseStep());
    }

    return (
        <>
            {contextHolder}
            <Row align={"middle"} justify="center">
                <Col span={12} style={{ maxWidth: "100%" }}>
                    <TPCalendar
                        initialEvents={generatedEvents}
                        ref={calendar}
                        handleDateClick={handleDateClick}
                        handleSelection={handleSelection}
                        removeDate={remove}
                    />
                </Col>
            </Row>
            <Row align={"middle"} justify={"center"} style={{ gap: 20, marginTop: 25 }}>
                <Button type="link" htmlType="button" onClick={handlePrevButton}>Geri</Button>
                <Button size="large" type="primary" htmlType="button" onClick={next}>İleri</Button>
            </Row>

        </>

    );

}

export default SelectDate;