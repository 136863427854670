// user için backend tarafında bir hata ile karşılaşılır ise 
// bilgilendirme / sistemin çalışmasını engelleme işlevleri yapılacak.

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setModuleHealth } from "../system-management/system-management-slice";
import { useAuth } from "../../hooks/useAuth";
import { getUserInformationsThunkAction } from "./user-thunk-action";


const UserDataBridge = () => {

    // redux
    const dispatch = useDispatch();

    // local state
    const [allFetchsComplete, setAllFetchsComplete] = useState(false);

    // hooks
    const { isAuthenticated } = useAuth();

    // functions
    const initialPreProcess = async () => { }

    const initialAuthPreProcess = async () => {
        return Promise.all([
            await dispatch(getUserInformationsThunkAction())
        ])
    }

    useEffect(() => {
        if(isAuthenticated) {
            initialAuthPreProcess().then(() => {
                setAllFetchsComplete(true)
            })  
        }
    }, [isAuthenticated])

    useEffect(() => {
        dispatch(setModuleHealth({
            moduleName: 'user',
            health: true
        }))
    }, [])

    return <></>
}

export default UserDataBridge;