import PropTypes from 'prop-types';
import NotFound from '../../pages/not-found/not-found';

const TPConditionalRender = ({ condition, viewOne, viewTwo }) => {
    return condition ? viewOne : viewTwo
}

export default TPConditionalRender;

TPConditionalRender.propTypes = {
    viewOne: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
    viewTwo: PropTypes.oneOf([PropTypes.string, PropTypes.node])
}

TPConditionalRender.defaultProps = {
    viewTwo: <NotFound
        buttonText={'Ana Sayfaya Dön'}
        title={"Ooopps ! Plan'da olmayan bir şeyler oldu :("}
    />
}