import React from "react";
import { Form, Input } from "antd";


const AddNewParticipantModalContentView = React.forwardRef(({ onFinish }, ref) => {

    /**
     * Form layout
     */
    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };

    /**
     * Form Validation Rules
     */
    const responsibleFormValidations = {
        title: [
            {
                required: true,
                message: 'Unvan alanı boş bırakılamaz'
            }
        ],
        name: [
            {
                required: true,
                message: 'İsim alanı boş bırakılamaz'
            }
        ],
        surname: [
            {
                required: true,
                message: "Soyisim alanı boş bırakılamaz"
            }
        ],
        institution: [
            {
                required: true,
                message: "Kurum alanı boş bırakılamaz"
            }
        ],
        email: [
            {
                required: true,
                message: "E-Posta alanı boş bırakılamaz"
            }
        ]
    }

    return (
        <Form
            {...layout}
            ref={ref}
            layout="vertical"
            name="control-ref"
            onFinish={onFinish}
            style={{ width: "100%" }}>

            <Form.Item
                name="title"
                label="Unvan"
                rules={responsibleFormValidations.name}>
                <Input />
            </Form.Item>

            <Form.Item
                name="name"
                label="İsim"
                rules={responsibleFormValidations.name}>
                <Input />
            </Form.Item>

            <Form.Item
                name="surname"
                label="Soyisim"
                rules={responsibleFormValidations.surname}>
                <Input />
            </Form.Item>

            <Form.Item
                name="institution"
                label="Kurum"
                rules={responsibleFormValidations.institution}>
                <Input />
            </Form.Item>

            <Form.Item
                name="email"
                label="E-Posta Adresi"
                rules={responsibleFormValidations.email}>
                <Input />
            </Form.Item>
        </Form>)
})


export default AddNewParticipantModalContentView;