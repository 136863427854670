import { format } from 'date-fns'

export const formatDate = (date, isTimeShow = true) => (
    format(new Date(date), `dd/MM/yyyy ${isTimeShow ? 'HH:mm' : ''}`)
)

/**
 * Zaman değerleri için tek haneli olan ve sıfır gerekli olduğu durumlarda kullanılır.
 * 
 * @param {number} num 
 * @returns 
 */
export const zeroPad = (num) => String(num).padStart(2, "0");

/**
 * Zaman değeri tek haneli ise bu formatter üzerinden zaman değeri alınır.
 * 
 * @param {number} value 
 * @returns 
 */
export const formatSingleDigit = (value) => String(value).length === 1 ? zeroPad(value) : value;
