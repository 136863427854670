import { Row, Space } from "antd";
import TPLoader from "./tp-loader";
import { memo } from "react";


const TPPageLoader = () => {

    const styles = { height: '100vh', width: '100vw' }

    return (
        <Space>
            <Row style={styles} align="middle" justify={"center"}>
                <TPLoader />
            </Row>
        </Space>
    )
}

export default memo(TPPageLoader);