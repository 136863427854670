import { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import { useAuth } from "./hooks/useAuth";
import { nonLoginUserRoutes, authenticatedUserRoutes } from "./routes";
import RootDataBridge from "./store/root/root-data-bridge";
import TPConditionalRender from "./components/tp-conditional-render/tp-conditional-render";
import TPPageLoader from "./components/tp-loader/tp-page-lodaer";
import './styles/main.css'

function App() {

  // hooks
  const { isAuthenticated } = useAuth();
  const isAllModulesAreReady = useSelector(state => state.systemManagementSlice.isAllModulesAreReady);

  // local states
  const [routes, setRoutes] = useState(nonLoginUserRoutes);


  // effects
  useLayoutEffect(() => {
    if (isAuthenticated && isAllModulesAreReady) {
      setRoutes(authenticatedUserRoutes);
    }
  }, [isAuthenticated, isAllModulesAreReady])

  let element = useRoutes(routes);

  return (
    <>
      <RootDataBridge />
      <TPConditionalRender
        condition={isAllModulesAreReady}
        viewOne={element}
        viewTwo={<TPPageLoader />}
      />

    </>
  )
}

export default App;