import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Divider, Form, Input, Result, Row, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth'
import toplanLogo from '../../assets/toplan_logo.png';

const Login = () => {

    // hooks
    const { login } = useAuth()
    const navigate = useNavigate();

    // functions
    const onFinish = async (values) => {
        const response = await login(values);
        if (response.status === 200) {
            navigate('/')
        } else {
            alert('hata !')
        }
    };

    return (
        <Row style={{ height: "100vh", background: "#f5f5f5" }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: "column", width: "100%" }}>
                <img alt='toplan_logo' src={toplanLogo} />

                <Form
                    style={{ width: 400 }}
                    onFinish={onFinish}>

                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Lütfen e-posta adresinizi giriniz.',
                            },
                        ]}>
                        <Input
                            size='large'
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            placeholder="Kurumsal E-Posta adresiniz."
                        />
                    </Form.Item>
                    <Form.Item
                        style={{ height: "40px" }}
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Lütfen şifrenizi giriniz.',
                            },
                        ]}>
                        <Input.Password
                            size='large'
                            height={100}
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            placeholder="Password"
                        />
                    </Form.Item>

                    {/* <Form.Item>
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>

                        <a className="login-form-forgot" href="">
                            Forgot password
                        </a>
                    </Form.Item> */}

                    <Form.Item style={{ marginTop: 20, display: "flex", justifyContent: "center" }}>
                        <Button type="primary" size='large' htmlType="submit">
                            Giriş Yap
                        </Button>
                    </Form.Item>
                    <Divider />
                    <Form.Item style={{ marginTop: 20 }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <Typography> Hesabınız yok mu ? </Typography>
                            <Button onClick={() => navigate('/register')} type="link" size='large'>
                                Kayıt Ol
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </Row >
    );
};

export default Login;