import MainLayout from "../components/layouts/main-layout";
import { Button, Popconfirm, Row, Space, Table, Tag } from "antd";
import { TABLE_PAGINATION_CONFIG } from '../constants/component-config-constants'
import { useDispatch, useSelector } from "react-redux";
import { useMemo } from "react";
import { PLAN_TYPES } from '../constants/common-constants';
import { Link } from "react-router-dom";
import { deletePlanThunkAction } from "../store/plan/plan-thunk.action";

const columns = (deleteConfirm, deleteCancel) => [
    {
        key: 'plan_name',
        title: 'Plan Adı',
        dataIndex: 'plan_name'
    },
    {
        key: 'age',
        title: 'Plan Sorumlusu',
        dataIndex: 'responsible',
    },
    {
        key: 'plan_type',
        title: 'Plan Türü',
        dataIndex: 'plan_type'
    },
    {
        key: 'is_anonymous',
        title: 'Anonim', // evetse yeşil hayırsa kırmızı
        dataIndex: 'is_anonymous',
        dataIndex: 'is_anonymous',
        render: (_, { is_anonymous, id }) => (
            <>
                <Tag color={is_anonymous ? 'success' : 'volcano'} key={id}>
                    {is_anonymous ? 'Evet' : 'Hayır'}
                </Tag>
            </>
        ),
    },
    {
        key: 'invited_participant',
        title: <Row align="middle" justify="center">
            Toplam katılımcı Sayısı
        </Row>,
        dataIndex: 'invited_participant',
        render: (_, record) => {
            return (
                <Row align="middle" justify="center">
                    {record.total_participant}
                </Row>
            )
        }
    },
    {
        key: 'action',
        dataIndex: "id",
        title: 'İşlem',
        render: (_, record) => (
            <Space size="middle">
                <Link to={`/plan/${record.id}/detail`} type="link"> Detay</Link>

                <Popconfirm
                    title="Sil"
                    description="Bu Planı silmek istediğinize emin misiniz ?"
                    onConfirm={() => deleteConfirm(record.id)}
                    onCancel={deleteCancel}
                    okText="Evet"
                    cancelText="Vazgeç"
                >
                    <Button danger>Sil</Button>
                </Popconfirm>
            </Space>
        ),
    },
];


const Home = () => {

    const dispatch = useDispatch();

    /**
     * @type {array}
     */
    const plans = useSelector(state => state.planSlice.plans);

    const generatePlanData = useMemo(() => {
        return plans?.map(({ plan_name, id, responsible_id, is_anonymous, invited_participant, responsible,
            total_participant, ...plan }) => ({
                id,
                key: id,
                plan_name,
                responsible: `${responsible.name} ${responsible.surname}`,
                address: plan.address,
                plan_type: plan.plan_type === PLAN_TYPES.PANEL ? 'Panel' : 'Toplantı',
                is_anonymous,
                invited_participant,
                total_participant,
            }))
    }, [JSON.stringify(plans)])


    const deleteProcess = async (isConfirmed, id) => {
        if (isConfirmed && id) {
            return dispatch(deletePlanThunkAction({ id }));
        }
    }

    return (
        <MainLayout>
            <Table
                columns={columns((id) => deleteProcess(true, id), () => deleteProcess())}
                dataSource={generatePlanData}
                pagination={TABLE_PAGINATION_CONFIG}
            />
        </MainLayout>
    )
}

export default Home;