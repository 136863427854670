import { memo } from "react";
import PropTypes from 'prop-types';
import { Col, Row } from "antd"

const TPSelectOptGroupLabel = ({ align, justify, style, labels, layout }) => {

    return (
        <Row justify={justify} align={align} style={style}>
            {labels.map((label, index) => (
                <Col span={layout[index] ?? 24}>{label}</Col>
            ))}
        </Row>
    )
}

TPSelectOptGroupLabel.defaultProps = {
    align: 'middle',
    justify: 'space-between',
    style: { width: '100%' },
    labels: [],
    layout: []
}

TPSelectOptGroupLabel.propTypes = {
    align: PropTypes.string,
    justify: PropTypes.string,
    style: PropTypes.object,
    labels: PropTypes.array,
    layout: PropTypes.array
}

export default memo(TPSelectOptGroupLabel, (prev, next) => JSON.stringify(prev.labels) !== JSON.stringify(next.labels));