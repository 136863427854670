import { Card, Col, Row, Image, Button, Space } from "antd";
import Meta from "antd/es/card/Meta";
import { memo } from "react"
import { useDispatch, useSelector } from "react-redux";
import { PANEL_TYPES } from "../../../constants/common-constants";
import { decreaseStep, increaseStep, setPanelData } from "../../../store/onboard/onboard-slice";


const PanelType = () => {

    /**
     * Redux
     */
    const dispatch = useDispatch();
    const panelType = useSelector(state => state.onboardSlice.onboardFormData.panel.panel_type)


    /**
     * Seçili panel tipi bu fonksiyon üzerinden redux'a gönderilir.
     * 
     * @param {string} type 
     */
    const handlePanelType = (type) => {
        dispatch(setPanelData({
            key: "panel_type",
            value: type
        }))
    }


    /**
     * İleri butonuna tıklandığında bu fonksiyon çalışır.
     */
    const onNextClick = () => {
        dispatch(increaseStep())
    }


    /**
     * Geri butonuna tıklandığında bu fonksiyon çalışır.
     */
    const onBackClick = () => {
        dispatch(decreaseStep())
    }

    return (
        <>
            <Row style={{ flexWrap: 'wrap', gap: 10, justifyContent: 'space-around', alignItems: 'center', height: '100%' }}>
                <Col span={11}>
                    <Card
                        onClick={() => handlePanelType(PANEL_TYPES.GROUP_PANEL)}
                        className={`${panelType === PANEL_TYPES.GROUP_PANEL && "card-selected"}`}
                        cover={<Image src={'/images/vectors/group.jpg'} preview={false} />} >
                        <Meta
                            style={{ textAlign: 'center' }}
                            title="Grubunuza Bağlı Paneller"
                            description="Grubunuza bağlı panelleri planlayın !"
                        />
                    </Card>
                </Col>

                <Col span={11}>
                    <Card
                        onClick={() => handlePanelType(PANEL_TYPES.PERSONAL_PANEL)}
                        className={`${panelType === PANEL_TYPES.PERSONAL_PANEL && "card-selected"}`}
                        cover={<Image src={'/images/vectors/personal.jpg'} preview={false} />} >
                        <Meta
                            style={{ textAlign: 'center' }}
                            title="Sorumlusu Olduğunuz Paneller"
                            description="Sorumlusu olduğunuz panelleri planlayın !"
                        />
                    </Card>
                </Col>
            </Row>
            <Row align={"middle"} justify={"center"}>
                <Space>
                    <Button onClick={onBackClick} size="large" >Geri</Button>
                    <Button onClick={onNextClick} size="large" disabled={!panelType} type="primary">İleri</Button>
                </Space>
            </Row>
        </>
    )
}

export default memo(PanelType);