import { Card, Col, Row, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import MainLayout from "../../components/layouts/main-layout";
import { useEffect } from "react";
import { findPlanFromPlanLinkThunkAction } from "../../store/plan/plan-thunk.action";
import { useNavigate, useParams } from "react-router-dom";
import ParticipantDateSelectionView from "../../views/participant/participant-date-selection-view";
import TPConditionalRender from "../../components/tp-conditional-render/tp-conditional-render";
import { getSelectableDatesThunkAction } from "../../store/participant/participant-thunk-action";
import TpPageLodaer from "../../components/tp-loader/tp-page-lodaer";

const ParticipantDateSelection = () => {

    const { plan_link } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const isPlanLinkFetching = useSelector(state => state.planSlice.isPlanLinkFetching);
    const planLinkData = useSelector(state => state.planSlice.planLinkData);
    const isLoggedIn = useSelector(state => state.participantSlice.participantAuth.isLoggedIn)
    const plan_dates = useSelector(state => state.participantSlice.participantAuth.plan_dates)

    useEffect(() => {
        if (isLoggedIn && plan_link) {
            dispatch(findPlanFromPlanLinkThunkAction({ plan_link }));
            dispatch(getSelectableDatesThunkAction({}));
        } else {
            navigate(`/participant/verify?reason=no_auth&plan=${plan_link}`);
        }
    }, [isLoggedIn, plan_link])

    const menuItems = [{ label: "Tarih Seçimi", to: window.location.pathname }]

    const CustomSkeleton = () => {
        return (
            <Row align={"middle"} justify={"center"}>
                <Col span={14}>
                    <Card style={{ width: 900 }}>
                        <Skeleton input={{}} active large />
                    </Card>
                </Col>
                <Col span={24}>
                    <Card style={{ height: "auto" }}>
                        <Skeleton input={{}} active large />
                        <Skeleton input={{}} active large />
                    </Card>
                </Col>
            </Row>
        )
    }

    return (
        <MainLayout menuItems={menuItems} isLoading={isPlanLinkFetching} skeleton={<CustomSkeleton />} isFullPageLoading={!isLoggedIn}>
            <TPConditionalRender
                condition={isLoggedIn && !isPlanLinkFetching && planLinkData !== null && plan_dates !== null}
                viewOne={<ParticipantDateSelectionView plan_dates={plan_dates} planLinkData={planLinkData} />}
                viewTwo={<TpPageLodaer />}
            />
        </MainLayout>
    )

}

export default ParticipantDateSelection;