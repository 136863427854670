import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setModuleHealth } from "../system-management/system-management-slice";
import { setIsOnboardFormFieldsValueAvailableThunkAction } from "./onboard-thunk-action";

const OnboardDataBridge = () => {

    const dispatch = useDispatch();

    const initialPreProcess = async () => {
        /**
        * TODO: bu alanda be tarafında module health requesti atıldıktan sonra health bilgisi true'ya çekilmeli
        */
        dispatch(setIsOnboardFormFieldsValueAvailableThunkAction());
    }

    useEffect(() => {
        (async function () {
            await initialPreProcess();

            dispatch(setModuleHealth({
                moduleName: 'onboarding',
                health: true
            }))
        })()
    }, [])

    return <></>
}

export default OnboardDataBridge;