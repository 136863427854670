import { createSlice } from "@reduxjs/toolkit";
import { STORAGE_KEYS } from '../../constants/common-constants'
import { savePlanThunkAction, setIsOnboardFormFieldsValueAvailableThunkAction } from "./onboard-thunk-action";

const initialState = {
    currentStep: 0,
    onboardFormData: {
        plan: {
            address: "",
            description: "",
            has_priority: false,
            is_anonymous: false,
            is_online: true,
            plan_name: "",
            responsible_id: null,
            plan_type: "",
            plan_link: "",
            isSaving: false
        },
        panel: {
            panel_name: "",
            panel_no: "",
            panel_responsible: "",
            panel_type: "",
        },
        participants: [],
        planDates: []
    }
}

export const onboardSlice = createSlice({
    name: 'onboardSlice',
    initialState: JSON.parse(JSON.stringify(initialState)),
    reducers: {
        decreaseStep: (state) => {
            if (state.currentStep > 0) {
                state.currentStep = state.currentStep - 1;
                localStorage.setItem(STORAGE_KEYS.onboardStepCount, state.currentStep)
            }
        },

        increaseStep: (state) => {
            state.currentStep = state.currentStep + 1;
            localStorage.setItem(STORAGE_KEYS.onboardStepCount, state.currentStep)
        },

        setCurrentStep: (state, { payload }) => {
            state.currentStep = payload ?? 0;
            localStorage.setItem(STORAGE_KEYS.onboardStepCount, payload ?? 0)
        },

        resetOnboardFields: (state) => {
            state.currentStep = 0;
            state.onboardFormData = initialState.onboardFormData;
        },

        // plan fields
        setPlanData: (state, { payload }) => {
            if (typeof payload === 'object') {
                if (payload.key) {
                    state.onboardFormData.plan[payload.key] = payload.value;
                } else {
                    state.onboardFormData.plan = {
                        ...state.onboardFormData.plan,
                        ...payload
                    }
                }
            }
        },

        // panel fields
        setPanelData: (state, { payload }) => {
            if (payload.key) {
                state.onboardFormData.panel[payload.key] = payload.value;
            } else {
                state.onboardFormData.panel = {
                    ...state.onboardFormData.panel,
                    ...payload
                };
            }
        },

        // plan date 
        setPlanDates: (state, { payload }) => {
            state.onboardFormData.planDates = [...payload];
        },

        // participants
        setParticipants: (state, { payload }) => {
            state.onboardFormData.participants = [...payload]
        }
    },

    extraReducers: ({ addCase }) => {
        addCase(setIsOnboardFormFieldsValueAvailableThunkAction.fulfilled, (state, { payload }) => {
            state.currentStep = payload.currentStep
        })

        addCase(savePlanThunkAction.pending, (state, { payload }) => {
            state.onboardFormData.plan.isSaving = true 
        })

        addCase(savePlanThunkAction.fulfilled, (state, { payload }) => {
            state.onboardFormData.plan.plan_link = payload.content.plan_link;
            state.onboardFormData.plan.isSaving = false 
        })

        addCase(savePlanThunkAction.rejected, (state, { payload }) => {
            state.onboardFormData.plan.isSaving = false 
        })
    }
})

export const {
    decreaseStep,
    increaseStep,
    setPlanData,
    setPanelData,
    setPlanDates,
    setParticipants,
    setCurrentStep,
    resetOnboardFields
} = onboardSlice.actions;

export default onboardSlice.reducer;