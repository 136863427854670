import { createSlice } from "@reduxjs/toolkit";
import { deletePlanParticipantThunkAction, deletePlanThunkAction, findAllPlansThunkAction, findOnePlanThunkAction, findPlanFromPlanLinkThunkAction, updateParticipantPlanDatesThunkAction, updatePlanParticipantPriorityThunkAction, updatePlanParticipantThunkAction, updatePlanThunkAction } from "./plan-thunk.action";

export const planSlice = createSlice({
    name: 'planSlice',
    initialState: {
        isPlansFetching: true,
        isSelectedPlanFetching: true,
        isPlanLinkFetching: true,
        plans: [],
        selectedPlan: null,
        planLinkData: null
    },
    reducers: {
        setIsSelectedPlanFetching: (state, { payload }) => {
            state.isSelectedPlanFetching = payload
        },
        addPlan: (state, { payload }) => {
            state.plans = [
                ...state.plans,
                payload
            ]
        }
    },
    extraReducers: ({ addCase }) => {
        addCase(findAllPlansThunkAction.fulfilled, (state, { payload }) => {
            state.isPlansFetching = false;
            state.plans = payload.content;
        })

        addCase(findAllPlansThunkAction.rejected, (state, { payload }) => {
            state.isPlansFetching = false;
            state.plans = [];
        })

        addCase(findOnePlanThunkAction.fulfilled, (state, { payload }) => {
            state.isSelectedPlanFetching = false;
            state.selectedPlan = payload.content;
        })

        addCase(findOnePlanThunkAction.rejected, (state, { payload }) => {
            state.isSelectedPlanFetching = false;
            state.selectedPlan = null;
        })

        addCase(findPlanFromPlanLinkThunkAction.fulfilled, (state, { payload }) => {
            state.isPlanLinkFetching = false;
            state.planLinkData = payload;
        })

        addCase(findPlanFromPlanLinkThunkAction.rejected, (state, { payload }) => {
            state.isPlanLinkFetching = false;
            state.planLinkData = null;
        })

        addCase(deletePlanThunkAction.fulfilled, (state, { payload }) => {
            state.plans = state.plans.filter(plan => plan.id !== payload.content.id)
        })

        addCase(updatePlanThunkAction.fulfilled, (state, { payload }) => {
            state.selectedPlan = {
                ...state.selectedPlan,
                ...payload
            }

            const findPlanIndex = state.plans.findIndex(plan => plan.id === payload.id);
            state.plans[findPlanIndex] = {
                ...state.plans[findPlanIndex],
                ...payload
            }
        })

        addCase(updatePlanParticipantThunkAction.fulfilled, (state, { payload }) => {
            const findPlanIndex = state.plans.findIndex(plan => plan.id === payload.content.plan_id);

            state.plans[findPlanIndex] = {
                ...state.plans[findPlanIndex],
                participants: [
                    ...state.plans[findPlanIndex].participants,
                    payload.content.participant
                ]
            }

            state.selectedPlan = {
                ...state.selectedPlan,
                participants: [
                    ...state.selectedPlan.participants,
                    payload.content.participant
                ]
            }

            state.planLinkData = {
                ...state.planLinkData,
                participants: [
                    ...state.planLinkData.participants,
                    payload.content.participant
                ]
            }

        })

        addCase(deletePlanParticipantThunkAction.fulfilled, (state, { payload }) => {

            state.selectedPlan.participants = state.selectedPlan.participants.filter(participant => participant.id !== payload.content.participant_id);
            state.planLinkData.participants = state.planLinkData.participants.filter(participant => participant.id !== payload.content.participant_id);

            const findPlanIndex = state.plans.findIndex(plan => plan.id === state.selectedPlan.id);
            const filteredParticipants = state.plans[findPlanIndex].participants.filter(participant => participant.id !== payload.content.participant_id);
            state.plans[findPlanIndex].participants = filteredParticipants;
        })

        addCase(updateParticipantPlanDatesThunkAction.fulfilled, (state, { payload }) => {
            const findPlanIndex = state.plans.findIndex(plan => plan.id === payload.content.id);
            state.plans[findPlanIndex] = payload.content.plan;

            if (state.selectedPlan) {
                state.selectedPlan = payload.content.plan;
            }

            if (state.planLinkData) {
                state.planLinkData = payload.content.plan;
            }
        })

        addCase(updatePlanParticipantPriorityThunkAction.fulfilled, (state, { payload }) => {
            state.selectedPlan.participants = state.selectedPlan.participants.map(participant => {
                const findIndexFromPayload = payload.content.findIndex(p => p.participant_id === participant.id);
                if(findIndexFromPayload > -1) {
                    participant.priority_order = payload.content[findIndexFromPayload].priority_order;  
                } 
                return participant;
            })
        })
    }
})

export const { setIsSelectedPlanFetching, addPlan } = planSlice.actions;

export default planSlice.reducer;