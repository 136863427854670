import { Card, Col, Row, Image, Button } from "antd";
import Meta from "antd/es/card/Meta";
import { memo } from "react"
import { useDispatch, useSelector } from "react-redux";
import { PLAN_TYPES } from "../../../constants/common-constants";
import { increaseStep, setPlanData } from "../../../store/onboard/onboard-slice";

const PlanType = () => {

    /**
     * Redux
     */
    const dispatch = useDispatch()
    const planType = useSelector(state => state.onboardSlice.onboardFormData.plan.plan_type)


    /**
     * Seçilen plan tipi bilgisini bu fonksiyon tutar
     * 
     * @param {string} type 
     */
    const handlePlanType = (type) => {
        dispatch(setPlanData({ key: "plan_type", value: type }))
    }


    /**
     * İleri tuşuna basıldığında bu fonksiyon tetiklenir.
     */
    const onNextClick = () => {
        dispatch(increaseStep());
    }

    return (
        <>
            <Row style={{ flexWrap: 'wrap', gap: 10, justifyContent: 'space-around', alignItems: 'center', height: '100%' }}>
                <Col span={11}>
                    <Card
                        onClick={() => handlePlanType(PLAN_TYPES.PANEL)}
                        className={`${planType === PLAN_TYPES.PANEL && "card-selected"}`}
                        cover={<Image src={'/images/vectors/panel.jpg'} preview={false} />} >
                        <Meta
                            style={{ textAlign: 'center' }}
                            title="Panel"
                            description="Sorumlusu olduğunuz yada Grubunuza bağlı panelleri planlayın !"
                        />
                    </Card>
                </Col>

                <Col span={11}>
                    <Card
                        onClick={() => handlePlanType(PLAN_TYPES.MEETING)}
                        className={`${planType === PLAN_TYPES.MEETING && "card-selected"}`}
                        cover={<Image src={'/images/vectors/meeting.jpg'} preview={false} />} >
                        <Meta
                            style={{ textAlign: 'center' }}
                            title="Toplantı"
                            description="Yeni bir toplantı planlayın !"
                        />
                    </Card>
                </Col>
            </Row>
            <Row align={"middle"} justify={"center"}>
                <Button onClick={onNextClick} size="large" disabled={!planType} type="primary">İleri</Button>
            </Row>
        </>
    )
}

export default memo(PlanType);