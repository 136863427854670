import React from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

const TPEditor = ({ onChange, name, value, ...props }) => {

    return (
        <CKEditor
            type=""
            name={name}
            editor={ClassicEditor}
            data={value}
            onChange={(event, editor) => {
                const data = editor.getData();
                onChange(data);
            }}
            {...props}
        />
    );
}

export default TPEditor;
