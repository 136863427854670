import { Select, Button, Modal, Typography, Row } from "antd";
import TpSelectOptGroupLabel from "./tp-select-opt-group-label";

const { Title } = Typography;

const TPSelect = ({
    onSelect,
    options,
    groupLabel,
    value,
    onAddNew,
    addNewText,
    loading,
    disabled = false,
    style = {},
    isModalOpen = false,
    modalProps = {}
}) => {

    const { labels, layout } = groupLabel;
    const { modalTitle, onOk, onCancel, okText, cancelText, modalWidth, modalContent } = modalProps;

    return (
        <>
            <Select
                showSearch
                style={{ width: "100%", ...style }}
                options={[{
                    label: <TpSelectOptGroupLabel labels={labels} layout={layout} />,
                    options
                }]}
                onSelect={onSelect}
                loading={loading}
                value={value}
                disabled={disabled}
                notFoundContent={<Button onClick={onAddNew} type="link">{addNewText}</Button>}
            />
            {
                modalContent && (
                    <Modal
                        title={<Title level={3}> {modalTitle || "Yeni Ekle"} </Title>}
                        open={isModalOpen}
                        onOk={() => onOk()}
                        onCancel={() => onCancel()}
                        okText={okText || "Kaydet"}
                        cancelText={cancelText || "Vazgeç"}
                        centered
                        width={modalWidth || 400}>
                        <Row style={{width: "100%"}}>
                            {modalContent}
                        </Row>
                    </Modal>
                )
            }
        </>
    )
}

export default TPSelect;

TPSelect.defaultProps = {
    options: [],
    groupLabel: {
        labels: [],
        layout: []
    }
}