import React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, Row, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { apiPost } from '../../helpers/api';
import useToast from '../../hooks/useToast';

const Register = () => {

    const navigate = useNavigate();
    const { contextHolder, showToast } = useToast()

    const onFinish = async (values) => {
        console.log('Received values of form: ', values);

        const domain = /@tubitak\.gov\.tr$/
        if(!domain.test(values.email)) {
            showToast('Hata', "Lütfen kurum e-posta adresiniz ile kayıt olunuz.", "error");
            return;
        }

        const registerRequest = await apiPost('/auth/register', values);

        // showToast('Kayıt işlemi başarılı', "Lütfen giriş yapın.", "success");
        
        if (registerRequest.status === 201) {
            showToast('Kayıt işlemi başarılı', "Lütfen giriş yapın.", "success");

            setTimeout(() => {
                navigate('/')
            }, 1500);

        }
    };

    return (
        <div style={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            {contextHolder}
            <Typography.Title level={2} >Kayıt Ol</Typography.Title>
            <Form
                layout='vertical'
                name="normal_login"
                style={{ width: "600px" }}
                onFinish={onFinish}>

                <Form.Item
                    name="name"
                    label="Ad"
                    rules={[{ required: true, message: 'Bu alan boş bırakılamaz' }]}>
                    <Input width={"100%"} prefix={<UserOutlined />} placeholder="Adınız" />
                </Form.Item>

                <Form.Item
                    name="surname"
                    label="Soyad"
                    rules={[{ required: true, message: 'Bu alan boş bırakılamaz' }]}>
                    <Input prefix={<UserOutlined />} placeholder="Soyadınız" />
                </Form.Item>

                <Form.Item
                    name="username"
                    label="Kullanıcı Adı"
                    rules={[{ required: true, message: 'Bu alan boş bırakılamaz' }]}>
                    <Input prefix={<UserOutlined />} placeholder="Kullanıcı adınız" />
                </Form.Item>

                <Form.Item
                    name="email"
                    label="E-Posta Adresi"
                    rules={[{ required: true, message: 'Bu alan boş bırakılamaz' }]}>
                    <Input prefix={<UserOutlined />} placeholder="E-Posta adresiniz" />
                </Form.Item>

                <Form.Item
                    name="password"
                    label="Şifre"
                    rules={[{ required: true, message: 'Bu alan boş bırakılamaz' }]}>
                    <Input
                        prefix={<LockOutlined />}
                        type="password"
                        placeholder="Şifreniz"
                    />
                </Form.Item>

                <Form.Item
                    name="password_confirmation"
                    label="Şifre Tekrarı"
                    rules={[{ required: true, message: 'Bu alan boş bırakılamaz' }]}
                >
                    <Input
                        prefix={<LockOutlined />}
                        type="password"
                        placeholder="Şifrenizi tekrar yazınız"
                    />
                </Form.Item>

                <Form.Item style={{ marginTop: 20, display: "flex", justifyContent: "center" }}>
                    <Button size='large' type="primary" htmlType="submit">
                        Kayıt Ol
                    </Button>
                </Form.Item>

                <Divider />
                <Form.Item style={{ marginTop: 20 }}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <Typography> Hesabınız var mı ? </Typography>
                        <Button onClick={() => navigate('/')} type="link" size='large'>
                            Giriş Yap
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Register;