export const PLAN_TYPES = {
    PANEL: 'PANEL',
    MEETING: 'MEETING'
}

export const PANEL_TYPES = {
    GROUP_PANEL: 'GROUP_PANEL',
    PERSONAL_PANEL: 'PERSONAL_PANEL'
}

export const STORAGE_KEYS = {
    onboardFields: 'toplan_onboard_fields',
    token: 'toplan_auth_token',
    onboardStepCount: 'toplan_onboard_step'
}
