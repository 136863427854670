import { Card, Col, Row, Skeleton } from "antd";
import MainLayout from "../../components/layouts/main-layout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { findOnePlanThunkAction, findPlanFromPlanLinkThunkAction } from "../../store/plan/plan-thunk.action";
import TPConditionalRender from '../../components/tp-conditional-render/tp-conditional-render'
import { setIsSelectedPlanFetching } from "../../store/plan/plan-slice";
import PlanDetailTabView from "../../views/plan/plan-detail-tab-view";
import TpPageLodaer from "../../components/tp-loader/tp-page-lodaer";
import { getSelectableDatesThunkAction } from "../../store/participant/participant-thunk-action";

const PlanDetailPage = () => {

    // hooks
    const params = useParams();

    // redux
    const dispatch = useDispatch();
    const { isSelectedPlanFetching, selectedPlan } = useSelector((state) => state.planSlice)
    const planLinkData = useSelector(state => state.planSlice.planLinkData);
    const plan_dates = useSelector(state => state.participantSlice.participantAuth.plan_dates)

    useEffect(() => {
        if (params.id) {
            (async () => await dispatch(findOnePlanThunkAction({ id: params.id })))()
        }

        return () => {
            dispatch(setIsSelectedPlanFetching(true))
        }
    }, [params?.id])


    useEffect(() => {
        if(selectedPlan?.plan_link) {
            dispatch(findPlanFromPlanLinkThunkAction({ plan_link: selectedPlan.plan_link }))
        }
    }, [selectedPlan?.plan_link])

    useEffect(() => {
        if(planLinkData?.plan_link) {
            dispatch(getSelectableDatesThunkAction({plan_link: planLinkData.plan_link}))
        }
    },[planLinkData?.plan_link])

    const CustomSkeleton = () => {
        return (
            <div>
                <Row>
                    <Card span={14}>
                        <Skeleton input={{}} active large />
                    </Card>
                    <Col span={8}>
                        <Card>
                            <Skeleton input={{}} active large />
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <MainLayout
            isLoading={isSelectedPlanFetching && selectedPlan !== null}
            skeleton={<CustomSkeleton />}>

            <TPConditionalRender condition={selectedPlan !== null}
                viewOne={<PlanDetailTabView plan_dates={plan_dates} selectedPlan={selectedPlan} planLinkData={planLinkData}/> }
                viewTwo={<TpPageLodaer />}
            />
        </MainLayout >
    )
}

export default PlanDetailPage;