import { Button, Card, Checkbox, Col, Collapse, Form, Input, Modal, Row, Space, Switch, Typography } from "antd";
import Meta from "antd/es/card/Meta";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { decreaseStep, increaseStep, setPlanData } from "../../../../store/onboard/onboard-slice";
import useToast from "../../../../hooks/useToast";
import TPSelect from "../../../../components/tp-select/tp-select";
import { apiPost } from "../../../../helpers/api";
import { addResponsible } from "../../../../store/responsible/responsible-slice";

const { Text, Title } = Typography;
const { Panel } = Collapse;

const PlanForm = ({ }) => {

    // refs
    const formRef = useRef();

    // local states
    const [showAddResponsibleModal, setShowAddResponsibleModal] = useState(false);
    const [responsibleOptions, setResponsibleOptions] = useState([]);
    const [addressShow, setAddressShow] = useState(false);

    // hooks
    const [form] = useForm();
    const { contextHolder, showToast } = useToast();

    // redux
    const onboardFormData = useSelector(state => state.onboardSlice.onboardFormData);
    const responsibles = useSelector(state => state.responsibleSlice.responsibles);
    const isAllResponsibleFetching = useSelector(state => state.responsibleSlice.isAllResponsibleFetching);
    const userId = useSelector(state => state.userSlice.currentUser.userId)

    const dispatch = useDispatch();

    // constants
    const { plan } = onboardFormData;

    // form layout
    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };


    /**
     * Form ile ilgili alanlar redux tarafında güncellenirse input alanlarına da yansıması için eklenmiştir.
     */
    useEffect(() => {
        if (form) {
            form.setFieldsValue(plan)
        }
    }, [JSON.stringify(plan), form])


    useEffect(() => {
        if (responsibles.length) {
            const optionsData = responsibles.map(option => ({
                value: JSON.stringify(option),
                label: (
                    <Row justify={"space-between"} align={"middle"} style={{ width: '100%' }}>
                        <Col span={12}>{option.name} {option.surname}</Col>
                        <Col span={6}>{option.institution}</Col>
                    </Row>
                )
            }))

            setResponsibleOptions(optionsData);
        }
    }, [JSON.stringify(responsibles)])


    /**
     * Geri butonuna tıklandığında tetiklenir.
     */
    const handlePrevButton = () => {
        dispatch(decreaseStep())
    };


    /**
     * Form başarılı bir şekilde submit edildiğinde tetiklenir.
     * 
     * @param {{}} values 
     */
    const onFinish = (values) => {
        dispatch(increaseStep());
        dispatch(setPlanData(values))
    }


    /**
     * Form submitlendiğinde validation gibi bir hata oluşur ise tetiklenir.
     * 
     * @param {{}} error 
     */
    const onError = (error) => {
        showToast("Uyarı", "Lütfen belirtilen alanları kontrol edin.", "error")
    }

    /**
     * Form'daki input alanları ile ilgili validation kuralları.
     */
    const validationRules = {
        plan_name: [
            {
                required: true,
                message: 'Plan adını girmelisiniz.'
            }
        ],
        description: [
            {
                max: 100,
                message: "En fazla 100 karakter yazabilirsiniz."
            }
        ],
        responsible_id: [
            {
                required: true,
                message: "Plan ile ilgili sorumluyu belirtmelisiniz."
            }
        ],
        meetingAddress: []
    }

    const responsibleFormValidations = {
        name: [
            {
                required: true,
                message: 'İsim alanı boş bırakılamaz'
            }
        ],
        surname: [
            {
                required: true,
                message: "Soyisim alanı boş bırakılamaz"
            }
        ],
        institution: [
            {
                required: true,
                message: "Kurum alanı boş bırakılamaz"
            }
        ],
        email: [
            {
                required: true,
                message: "E-Posta alanı boş bırakılamaz"
            }
        ]
    }

    const groupLabel = {
        labels: ['İsim Soyisim', 'Kurum'],
        layout: [12, 6]
    }

    const onResponsibleSelect = (value) => {
        form.setFieldValue('responsible_id', value);
    }

    const onAddNewResponsible = () => {
        setShowAddResponsibleModal(true);
    }

    const onAddNewResponsibleFinish = async (values) => {
        apiPost('/responsible', values)
            .then(data => {
                const selectableData = {
                    value: JSON.stringify(data.content),
                    label: (
                        <Row justify={"space-between"} align={"middle"} style={{ width: '100%' }}>
                            <Col span={12}>{data.content.name} {data.content.surname}</Col>
                            <Col span={6}>{data.content.institution}</Col>
                        </Row>
                    )
                }
                onResponsibleSelect(selectableData.value);
                setShowAddResponsibleModal(false);
                showToast('Başarılı', 'Sorumlu eklendi !', "success")
                setResponsibleOptions(prev => ([
                    ...prev,
                    selectableData
                ]))
                dispatch(addResponsible(data.content))
            })
            .catch(error => {
                console.log("***-error", error);
                showToast('Hata', 'Sorumlu kayıt edilemedi !', "error")
            })
    }

    const setResponsibleFieldValue = () => {
        if (userId && !form.getFieldValue('responsible_id') && !plan.responsible_id) {
            form.setFieldValue('responsible_id', responsibleOptions.find(rop => JSON.parse(rop.value).id === userId)?.value)
        } else if (plan.responsible_id && !form.getFieldValue('responsible_id')) {
            form.setFieldValue('responsible_id', plan.responsible_id);
        }
    }


    return (
        <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
            {contextHolder}
            <Card title="Bir Etkinlik Planlayın !" style={{ width: 850 }}>
                <Form
                    form={form}
                    name="createPlan"
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 18 }}
                    layout="horizontal"
                    size={"large"}
                    onFinishFailed={onError}
                    onFinish={onFinish}>

                    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                        <Form.Item label="Plan adı" name={"plan_name"} rules={validationRules.plan_name}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Açıklama" name={"description"} rules={validationRules.description}>
                            <TextArea showCount maxLength={100} />
                        </Form.Item>
                        <Form.Item label="Sorumlu" name={"responsible_id"} rules={validationRules.responsible_id} >
                            <TPSelect
                                loading={isAllResponsibleFetching}
                                disabled={isAllResponsibleFetching}
                                addNewText="Yeni Katılımcı Ekle"
                                onAddNew={onAddNewResponsible}
                                groupLabel={groupLabel}
                                options={responsibleOptions}
                                onSelect={onResponsibleSelect}
                                value={setResponsibleFieldValue()}
                            />
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 0, span: 1 }} label="Çevrimiçi mi ?" valuePropName="checked" name={"is_online"} tooltip="Eğer seçili olur ise toplantının çevrim içi olacağı anlamına gelir.">
                            <Checkbox name="is_online" onChange={(e) => setAddressShow(!e.target.checked)} />
                        </Form.Item>
                        {
                            addressShow && (
                                <Form.Item label="Adres" name={"address"}>
                                    <Input />
                                </Form.Item>
                            )
                        }
                    </Space>

                    <Collapse ghost activeKey={onboardFormData.isHasParticipantPriorty || onboardFormData.isHasAnonymousSurvey ? "optional-fields" : undefined} >
                        <Panel header={<Text strong>Gelişmiş Seçenekler (isteğe bağlı)</Text>} key="optional-fields">
                            <Space direction="vertical" size="large" style={{ display: 'flex', alignItems: "center" }}>
                                <Row>
                                    <Space size="large" style={{ display: 'flex', }}>
                                        <Card style={{ width: 350, height: 150, backgroundColor: "#efeff5" }}>
                                            <Form.Item valuePropName="checked" labelCol={{ span: 12 }} wrapperCol={{ span: 4 }} label="Katılımcı Önceliği" name={"has_priority"}>
                                                <Switch />
                                            </Form.Item>
                                            <Meta description="Etkinlik tarihi öncelikli kişilere göre belirlenebilir."
                                            />
                                        </Card>
                                        <Card style={{ width: 350, height: 150, backgroundColor: "#efeff5" }}>
                                            <Form.Item valuePropName="checked" labelCol={{ span: 12 }} wrapperCol={{ span: 4 }} label="Anonim Anket" name={"is_anonymous"}>
                                                <Switch />
                                            </Form.Item>
                                            <Meta description="Oylar gizlidir. Katılımcılar sadece kendi oylarını görebilir." />
                                        </Card>
                                    </Space>
                                </Row>
                            </Space>
                        </Panel>
                    </Collapse>

                    <Row align={"middle"} justify={"end"} style={{ gap: 20 }}>
                        <Button type="link" htmlType="button" onClick={handlePrevButton}>Geri</Button>
                        <Button size="large" type="primary" htmlType="submit">İleri</Button>
                    </Row>

                </Form>
            </Card>

            <Modal
                title={<Title level={3}> Sorumlu Ekle </Title>}
                open={showAddResponsibleModal}
                onOk={() => formRef.current.submit()}
                onCancel={() => setShowAddResponsibleModal(false)}
                okText="Kaydet"
                cancelText="Vazgeç"
                width={400}>
                <Col>
                    <Form
                        {...layout}
                        ref={formRef}
                        layout="vertical"
                        name="control-ref"
                        onFinish={onAddNewResponsibleFinish}
                        style={{ maxWidth: 600 }}>

                        <Form.Item
                            name="name"
                            label="İsim"
                            rules={responsibleFormValidations.name}>
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="surname"
                            label="Soyisim"
                            rules={responsibleFormValidations.surname}>
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="institution"
                            label="Kurum"
                            rules={responsibleFormValidations.institution}>
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="email"
                            label="E-Posta Adresi"
                            rules={responsibleFormValidations.email}>
                            <Input />
                        </Form.Item>
                    </Form>
                </Col>
            </Modal>

        </Row>
    )

}

export default PlanForm;