import { configureStore } from "@reduxjs/toolkit";
import userSlice  from "./user/user-slice";
import onboardSlice  from "./onboard/onboard-slice";
import systemManagementSlice from "./system-management/system-management-slice";
import planSlice from "./plan/plan-slice";
import participantSlice from "./participant/participant-slice";
import responsibleSlice from "./responsible/responsible-slice";

export const store = configureStore({
    reducer: {
        userSlice,
        onboardSlice,
        systemManagementSlice,
        planSlice,
        participantSlice,
        responsibleSlice
    }
})