import { Button, Col, Row, Typography } from "antd";


/**
 * Card'ların title bilgileri bu component üzerinden alınır.
 * 
 * @param {{title: string, actions: {node: ReactNode, onClick: Function}[]}} props 
 * @returns 
 */
const TPCardTitle = ({ title, titleLevel = 5, children }) => {

    return (
        <Row align={"middle"} justify={"space-between"}>
            <Col>
                <Typography.Title level={titleLevel}> {title} </Typography.Title>
            </Col>
            <Col>
                {children}
            </Col>
        </Row>
    )
}

export default TPCardTitle;