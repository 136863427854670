import OnboardDataBridge from "../onboard/onboard-data.bridge";
import ParticipantDataBridge from "../participant/participant-data-bridge";
import PlanDataBridge from "../plan/plan-data-bridge";
import ResponsibleDataBridge from "../responsible/responsible-data-bridge";
import SystemManagementDataBridge from "../system-management/system-management-data-bridge";
import UserDataBridge from "../user/user-data-bridge";

const RootDataBridge = () => {

    return (
        <>
            <UserDataBridge />
            <PlanDataBridge />
            <ParticipantDataBridge/>
            <OnboardDataBridge />
            <SystemManagementDataBridge /> 
            <ResponsibleDataBridge />
        </>
    )
}

export default RootDataBridge;