import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { memo } from 'react';

const icon = (
    <LoadingOutlined
        style={{
            fontSize: 24,
        }}
        spin
    />
);

const TPLoader = () => {
    return <Spin indicator={icon} />
}

export default memo(TPLoader);
