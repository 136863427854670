import { Menu } from "antd";
import { memo, useLayoutEffect, useState } from "react"
import { useNavigate } from "react-router-dom";


function TPMenu({ menuItems = [], style = {} }) {

    const navigate = useNavigate()

    const [selected, setSelected] = useState([])
    const [items, setItems] = useState([]);

    const onMenuItemClick = ({ to, key }) => {
        setSelected([key]);
        setTimeout(() => {
            navigate(to)
        }, 100);
    }

    useLayoutEffect(() => {
        setItems(prev => {
            return menuItems.map(({ label, to }) => ({
                key: label.split(" ").join("-"),
                label: label,
                onClick: () => onMenuItemClick({ to, key: label.split(" ").join("-") })
            }))
        })
    }, [JSON.stringify(menuItems)])

    return (
        <Menu
            style={{ flex: 1, minWidth: 0, ...style }}
            theme="light"
            mode="horizontal"
            selectedKeys={selected}
            items={items}
        />
    )
}

export default memo(TPMenu);