import Login from "../pages/auth/login";
import Register from "../pages/auth/register";
import Home from "../pages/home";
import NotFound from "../pages/not-found/not-found";
import ParticipantDateSelection from "../pages/participants/participant-date-selection";
import ParticipantLogin from "../pages/participants/participant-login";
import ManageSteps from "../pages/plan-onboarding/steps/plan-form/manage-steps";
import PlanDetailPage from "../pages/plan/plan-detail";


const commonRoutes = [
    {
        path: '*',
        element: <NotFound />,
    },
];

export const nonLoginUserRoutes = [
    {
        path: '/',
        element: <Login />,
        isPrivate: false,
        exact: true,
    },
    {
        path: '/register',
        element: <Register />,
        isPrivate: false,
        exact: true,
    },
    {
        path: '/participant/verify',
        element: <ParticipantLogin />
    },
    {
        path: '/participant/participant-date-selection/:plan_link',
        element: <ParticipantDateSelection/>,
    },
    ...commonRoutes
]

export const authenticatedUserRoutes = [
    {
        path: "/",
        children: [
            {
                index: true,
                element: <Home />,
            },
            {
                path: "create-plan",
                element: <ManageSteps />,
                children: []
            },
            {
                path: 'plan',
                children: [
                    {
                        path: ':id/detail',
                        element: <PlanDetailPage />
                    }
                ]
            }
        ],
    },
    {
        path: '/login',
        element: <>Login</>,
    },

    ...commonRoutes
]