import { useEffect, useState, useRef, lazy, Suspense, useMemo } from "react";
import { Badge, Card, Descriptions, Row, Table, Radio, Button, Drawer, Col, Tag, Divider } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { formatDate, formatSingleDigit } from "../../helpers/date-time-util";
import { getMinutes, getHours } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { getSelectableDatesThunkAction, saveParticipantDatesThunkAction } from "../../store/participant/participant-thunk-action";
import useToast from '../../hooks/useToast';
import { updateParticipantPlanDatesThunkAction } from "../../store/plan/plan-thunk.action";

// lazy loads
const TPCalendar = lazy(() => import('../../components/tp-calendar/tp-calendar'))

const ParticipantDateSelectionView = ({ planLinkData, isResponsibleView, plan_dates }) => {

    // refs
    const calendarRef = useRef();

    // local states
    const [isopenEditDrawer, setOpenEditDrawer] = useState(false);

    // props
    const {
        plan_name,
        description,
        meeting_address,
        is_online,
        responsible
    } = planLinkData

    // redux
    const dispatch = useDispatch();
    const currentParticipant = useSelector(state => state.participantSlice.participantAuth.currentParticipant)
    const priorityState = useSelector(state => state.participantSlice.priorityState)

    // hooks
    const { contextHolder, showToast } = useToast()

    // local states
    const [selectedValue, setSelectedValue] = useState([]);

    useEffect(() => {
        let interval;

        if (isResponsibleView) {
            interval = setInterval(() => {
                dispatch(getSelectableDatesThunkAction({ plan_link: planLinkData.plan_link }))
            }, 5000)
        }

        return () => {
            if (interval) {
                clearInterval(interval)
            }
        }
    }, [isResponsibleView])


    // functions
    const onSelectionChange = (value, dateId) => {

        setSelectedValue(prev => {
            return {
                ...prev,
                [`${dateId}-${currentParticipant.id}`]: {
                    is_accept: value
                }
            }
        })
    }

    const getDefaultRadioValue = (participantId, dateId) => {

        if (selectedValue[`${dateId}-${participantId}`]) {
            return selectedValue[`${dateId}-${participantId}`].is_accept;
        }

        if (plan_dates && plan_dates[participantId]) {
            const index = plan_dates[participantId].findIndex(pdp => pdp.plan_date_id === dateId);
            if (index > -1) {
                return plan_dates[participantId][index].is_accept;
            }
        }

        return null;
    }

    // date titles
    const DateTitle = ({ date }) => {
        return (
            <Row align={"middle"} justify={"center"} style={{ flexDirection: "column" }}>
                <Row>
                    {`${formatDate(Number(date.start_date), false)}`}
                </Row>
                {
                    date.is_all_day
                        ? ("(Tüm Gün)")
                        : (
                            <Row>
                                ( {`${formatSingleDigit(getHours(Number(date.start_date)))}:${formatSingleDigit(getMinutes(Number(date.start_date)))}`}
                                -
                                {`${formatSingleDigit(getHours(Number(date.end_date)))}:${formatSingleDigit(getMinutes(Number(date.end_date)))}`} )
                            </Row>
                        )
                }
            </Row>
        )
    }

    const BadgeTitle = ({ date }) => {
        let badge = null;
        if (priorityState && priorityState.length) {
            const findIndexForPriority = priorityState.findIndex(state => state.plan_date_id === date.id);
            badge = priorityState[findIndexForPriority];
        }

        return (
            <Row align={"middle"} justify={"space-between"} style={{ flexDirection: "column" }}>
                <Col style={{ height: 10 }}>
                    {badge?.accept}
                </Col>
                <Divider />
                <Col style={{ height: 30 }}>
                    {badge?.tag && (
                        <Tag color="red">
                            {badge.tag}
                        </Tag>
                    )}
                </Col>
            </Row>
        )

    }

    // generate table columns
    const GeneratedColumns = () => {

        const optionsWithDisabled = [
            { label: <CloseOutlined />, value: false },
            { label: <CheckOutlined />, value: true },
        ];

        const participantColumn = (date) => ({
            title: <DateTitle date={date} />,
            key: date.start_date,
            width: "120px",
            render: (item) => {
                return (
                    <Row align={"middle"} justify={"center"}>
                        <Radio.Group
                            disabled={isResponsibleView || currentParticipant.id !== item.id}
                            className={`tp-radio-group  ${getDefaultRadioValue(item.id, date.id) === null ? '' : getDefaultRadioValue(item.id, date.id) ? 'green' : 'red'}`}
                            options={optionsWithDisabled}
                            onChange={(e) => onSelectionChange(e.target.value, date.id)}
                            value={getDefaultRadioValue(item.id, date.id)}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </Row>
                )
            }
        });

        const responsibleColumn = (date) => ({
            title: <BadgeTitle date={date} />,
            children: [participantColumn(date)]
        })

        const dateMap = planLinkData.plan_dates ? planLinkData.plan_dates.map(date => {
            if (isResponsibleView) {
                return responsibleColumn(date);
            } else {
                return participantColumn(date);
            }
        }) : [];

        const participantNameColumn = () => ({
            title: 'Ad Soyad',
            width: "120px",
            key: 'name',
            fixed: 'left',
            render: (row) => `${row.name} ${row.surname}`
        });

        const responsibleNameColumn = () => ({
            title: <Row style={{ flexDirection: "column" }}>
                <Col style={{ height: 10 }}>
                    Katılımcı Sayısı
                </Col>
                <Divider />
                <Col style={{ height: 30 }}>
                    Durum
                </Col>
            </Row>,
            children: [participantNameColumn()]
        })

        dateMap.unshift(isResponsibleView ? responsibleNameColumn() : participantNameColumn());

        return dateMap;
    }

    const handleSubmit = async () => {

        const prepareValue = Object.keys(selectedValue).map(key => {
            const [plan_date_id, participant_id] = key.split('-');
            return {
                plan_date_id,
                is_accept: selectedValue[key].is_accept
            }
        })

        const response = await dispatch(saveParticipantDatesThunkAction(prepareValue))
        if (response.payload.status === 201) {
            showToast('Başarılı', 'Tarih seçimleriniz kayıt edildi.', 'success')
        } else {
            showToast('Hata', 'Seçtiğiniz tarihler kayıt edilemedi !', 'error')
        }
    }

    const openEditDrawer = () => {
        setOpenEditDrawer(true)
    }

    const remove = (selectedEvent) => {
        selectedEvent.event.remove();
    }

    const handleDateSelection = (dateInfo) => {
        let calendarApi = dateInfo.view.calendar;

        const data = {
            id: calendarApi.getEvents().length,
            start: dateInfo.startStr,
            end: dateInfo.endStr,
            allDay: dateInfo.allDay
        };

        calendarApi.addEvent(data)
    }

    const saveUpdatedDates = () => {
        const dateMap = calendarRef.current.calendar.getEvents().map(data => {
            const event = calendarRef.current.calendar.getEventById(data._def.publicId);
            return {
                start_date: event.start.getTime(),
                end_date: event.end.getTime(),
                start_date_str: event.startStr,
                end_date_str: event.endStr,
                is_all_day: event.allDay,
                plan_id: planLinkData.id
            }
        });

        dispatch(updateParticipantPlanDatesThunkAction({
            plan_id: planLinkData.id,
            plan_dates: dateMap
        }));

        setOpenEditDrawer(false);

    }

    const generatedEvents = useMemo(() => {
        if (planLinkData?.plan_dates.length > 0) {
            return planLinkData?.plan_dates.map(({ start_date, end_date, is_all_day, id }, i) => ({
                id: i,
                start: Number(start_date),
                end: Number(end_date),
                allDay: is_all_day
            }))
        }
        return []
    }, [JSON.stringify(planLinkData?.plan_dates)])

    return (
        <>
            {contextHolder}
            <Row style={{ alignItems: 'center', justifyContent: 'center', display: isResponsibleView ? "none" : undefined }}>
                <Card style={{ width: 900 }}>
                    <Descriptions title="Plan Özeti" layout="horizontal" bordered column={6}>
                        <Descriptions.Item label="Plan Adı" span={3}>{plan_name}</Descriptions.Item>
                        <Descriptions.Item label="Sorumlu" span={3}>{`${responsible?.name} ${responsible?.surname}`}</Descriptions.Item>

                        <Descriptions.Item label="Açıklama" span={6}>{description || <i style={{ color: "gray" }}>Belirtilmedi</i>}</Descriptions.Item>
                        {!is_online && <Descriptions.Item label="Adres" span={6}>{meeting_address}</Descriptions.Item>}

                        <Descriptions.Item label="Çevrimiçi" span={2}>
                            <Badge status={`${is_online ? 'success' : 'error'}`} text={`${is_online ? 'Evet' : 'Hayır'}`} />
                        </Descriptions.Item>
                    </Descriptions>
                </Card>
            </Row>
            <Row style={{ alignItems: 'center', justifyContent: 'center', marginTop: 15 }}>
                <Table
                    bordered
                    loading={false}
                    columns={GeneratedColumns(planLinkData.plan_dates)}
                    dataSource={planLinkData.participants}
                    scroll={{ x: 0 }}
                    pagination={planLinkData.participants?.length > 10 ? undefined : false}
                    sticky
                    size="middle"
                />
                <Row justify={"end"} style={{ width: "100%", marginTop: 15 }}>
                    <Button style={{ display: !isResponsibleView ? undefined : 'none' }} onClick={handleSubmit}>Kaydet</Button>
                    <Button type="dashed" style={{ display: isResponsibleView ? undefined : 'none' }} onClick={openEditDrawer}> Düzenle</Button>
                </Row>


            </Row>

            <Drawer
                title={`Tarihleri Düzenle`}
                placement="right"
                size={"large"}
                onClose={() => setOpenEditDrawer(false)}
                open={isopenEditDrawer}
                extra={
                    <Button type="primary" onClick={saveUpdatedDates}>
                        Kaydet
                    </Button>
                }>
                <Suspense fallback={"Yükleniyor..."}>
                    <TPCalendar
                        handleSelection={handleDateSelection}
                        initialEvents={generatedEvents}
                        ref={calendarRef} removeDate={remove} />
                </Suspense>
            </Drawer>
        </>
    )
}

export default ParticipantDateSelectionView;