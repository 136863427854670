import { Badge, Button, Card, Col, Descriptions, List, Row, Tag, Timeline } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { decreaseStep, increaseStep } from "../../../../store/onboard/onboard-slice";
import { ClockCircleOutlined } from "@ant-design/icons";
import { savePlanThunkAction } from "../../../../store/onboard/onboard-thunk-action";
import useToast from "../../../../hooks/useToast";
import { formatDate } from "../../../../helpers/date-time-util";


const Summary = () => {

    // hooks
    const { contextHolder, showToast } = useToast();

    // redux
    const onboardFormData = useSelector(state => state.onboardSlice.onboardFormData);
    const dispatch = useDispatch();

    const {
        plan,
        panel,
        participants,
        planDates
    } = onboardFormData;

    // plan fields
    const { address, description, has_priority, is_anonymous, is_online, plan_name, responsible_id, isSaving } = plan;

    // panel fields
    const { panel_responsible } = panel;

    const handlePrevButton = () => {
        dispatch(decreaseStep())
    };

    const handleNextButton = async () => {
        const responsible = JSON.parse(responsible_id);
        const payload = {
            ...onboardFormData,
            plan: {
                ...onboardFormData.plan,
                responsible_id: responsible.id
            },
            responsible
        }

        const response = await dispatch(savePlanThunkAction(payload));
        if (response.payload.status === 201) {
            showToast('Başarılı', 'Plan oluşturuldu', "success")
            dispatch(increaseStep())
        } else {
            showToast("Hata", response.payload.message);
        }
    };

    const priorityOrderMap = {
        LOW: {
            text: "Düşük",
            color: "gray"
        },

        MEDIUM: {
            text: "Normal",
            color: "orange"
        },

        HIGH: {
            text: "Yüksek",
            color: "green"
        }
    }


    return (
        <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
            {contextHolder}
            <Card style={{ width: 900 }}>
                <Descriptions title="Plan Özeti" layout="horizontal" bordered column={6}>
                    <Descriptions.Item label="Plan Adı" span={3}>{plan_name}</Descriptions.Item>
                    <Descriptions.Item label="Sorumlu" span={3}>{JSON.parse(responsible_id).name} {JSON.parse(responsible_id).surname}</Descriptions.Item>

                    <Descriptions.Item label="Açıklama" span={6}>{description && description.length > 0 ? description : (<i>Belirtilmedi</i>)}</Descriptions.Item>

                    {!is_online && <Descriptions.Item label="Adres" span={6}>{address}</Descriptions.Item>}

                    <Descriptions.Item label="Çevrimiçi" span={2}>
                        <Badge status={`${is_online ? 'success' : 'error'}`} text={`${is_online ? 'Evet' : 'Hayır'}`} />
                    </Descriptions.Item>
                    <Descriptions.Item label="Katılımcı Önceliği" span={2}>
                        <Badge status={`${has_priority ? 'success' : 'error'}`} text={`${has_priority ? 'Evet' : 'Hayır'}`} />
                    </Descriptions.Item>
                    <Descriptions.Item label="Anonim Anket" span={2}>
                        <Badge status={`${is_anonymous ? 'success' : 'error'}`} text={`${is_anonymous ? 'Evet' : 'Hayır'}`} />
                    </Descriptions.Item>
                    <Descriptions.Item label="Seçilen Tarihler" span={6}>
                        <br />
                        <Timeline
                            items={[...planDates].sort((a, b) => a.start_date - b.start_date).map(date => {
                                if (date.is_all_day) {
                                    return {
                                        children:
                                            <Row>
                                                <Tag style={{ display: 'flex', alignItems: 'center', fontSize: 13, padding: "5px 10px" }} icon={<ClockCircleOutlined />} color="default">
                                                    {`${formatDate(date.start_date_str, false)} (Tüm Gün)`}
                                                </Tag>
                                            </Row>
                                    }
                                }

                                return {
                                    children: (
                                        <Row>
                                            <Tag style={{ display: 'flex', alignItems: 'center', fontSize: 13, padding: "5px 10px" }} icon={<ClockCircleOutlined />} color="default">
                                                {formatDate(date.start_date_str)}
                                            </Tag>
                                            - &nbsp;
                                            <Tag style={{ display: 'flex', alignItems: 'center', fontSize: 13, padding: "5px 10px" }} icon={<ClockCircleOutlined />} color="default">
                                                {formatDate(date.end_date_str)}
                                            </Tag>
                                        </Row>
                                    )
                                }
                            })}
                        />

                    </Descriptions.Item>
                    <Descriptions.Item label="Katılımcı Listesi" span={6}>
                        <List
                            itemLayout="horizontal"
                            dataSource={participants}
                            renderItem={item => {
                               return has_priority ? (
                                    <Badge.Ribbon {...priorityOrderMap[item.priority_order]}>
                                        <List.Item key={item.no}>
                                            <List.Item.Meta
                                                title={`${item.title} ${item.name} ${item.surname}`}
                                                description={item.institution} />
                                        </List.Item>
                                    </Badge.Ribbon>
                                )
                                    : (
                                        <List.Item key={item.no}>
                                            <List.Item.Meta
                                                title={`${item.title} ${item.name} ${item.surname}`}
                                                description={item.institution} />
                                        </List.Item>)
                            }}
                        />
                    </Descriptions.Item>
                </Descriptions>
                <Row align={"middle"} justify={"center"} style={{ gap: 20, marginTop: 25 }}>
                    <Button type="link" htmlType="button" onClick={handlePrevButton} disabled={isSaving}>Geri</Button>
                    <Button size="large" type="primary" onClick={handleNextButton} htmlType="button" loading={isSaving}>Kaydet</Button>
                </Row>
            </Card>
        </Row>
    )

}

export default Summary;