import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setModuleHealth } from "../system-management/system-management-slice";
import { findAllPlansThunkAction } from "./plan-thunk.action";
import { useAuth } from "../../hooks/useAuth";

const PlanDataBridge = () => {

    const dispatch = useDispatch();
    const { isAuthenticated } = useAuth()

    const initialPreProcess = async () => { }

    const initialAuthPreProcess = async () => {
        await dispatch(findAllPlansThunkAction(['panel', 'plan_dates', 'participants', 'responsible']));
    }

    useEffect(() => {
        if (isAuthenticated) {
            (async () => {
                await initialAuthPreProcess();
            })()
        }
    }, [isAuthenticated])

    useEffect(() => {
        (async function () {
            await initialPreProcess();

            dispatch(setModuleHealth({
                moduleName: 'plan',
                health: true
            }))
        })()
    }, [])

    return <></>
}

export default PlanDataBridge;