import { createSlice } from "@reduxjs/toolkit";
import { getAllResponsible } from "./responsible-thunk-action";

export const responsibleSlice = createSlice({
    name: 'responsibleSlice',
    initialState: {
        isAllResponsibleFetching: true,
        responsibles: []
    },
    reducers: {
        addResponsible: (state, {payload}) => {
            state.responsibles = [
                ...state.responsibles,
                payload
            ]
        }
    },
    extraReducers: ({ addCase }) => {
        addCase(getAllResponsible.fulfilled, (state, { payload }) => {
            state.isAllResponsibleFetching = false;
            state.responsibles = payload;
        })

        addCase(getAllResponsible.rejected, (state, { payload }) => {
            state.isAllResponsibleFetching = false;
            state.responsibles = [];
        })
    }
})

export const { addResponsible } = responsibleSlice.actions;

export default responsibleSlice.reducer;