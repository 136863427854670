import React, { useLayoutEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { STORAGE_KEYS } from "../constants/common-constants";
import { loginThunkAction, logoutThunkAction } from "../store/user/user-thunk-action";

/**
 * Auth Context
 */
const AuthContext = React.createContext(null);

/**
 * Auth Provider
 */
const AuthProvider = ({ children }) => {
    // local states
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [authCheckLoad, setAuthCheckLoad] = useState(true);

    // hooks
    const dispatch = useDispatch();

    // effects
    useLayoutEffect(() => {
        /**
         * ! Bu alanda check access token kontrolü be tarafından gelen response'a göreyapılacak.
         * ! token yoksa isAuthenticated değeri false olarak setlenecek.
         */
        if (localStorage.getItem(STORAGE_KEYS.token)) {
            setIsAuthenticated(true);
            setAuthCheckLoad(false);
        }
    }, [])


    /**
     * Hook içerisinde login işlemi bu fonksiyon üzerinden gerçekleştirilir.
     * 
     * @param {{email: string, password: string}} parameters 
     */
    const login = async ({ email, password }) => {
        try {
            
            const loginRequest = await dispatch(loginThunkAction({ email, password }));
            
            if (loginRequest.payload.status === 200) {
                setIsAuthenticated(true);
                localStorage.setItem(STORAGE_KEYS.token, loginRequest.payload.content.token)
            }

            return loginRequest.payload;

        } catch (e) {
            throw new Error(e);
        }
    }

    /**
     * Logout işlemi bu fonksiyon içerisinde gerçekleştirilir.
     */
    const logout = async () => {
        try {
            const logoutRequest = await dispatch(logoutThunkAction())
            if (logoutRequest) {
                window.location.reload();
            }
        } catch (e) {
            throw new Error(e);
        }
    }

    let value = { login, logout, isAuthenticated, authCheckLoad };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

/**
 * Auth Context'i bir hook gibi kullanabilmek için bu fonksiyon çağrılır.
 */
function useAuth() {
    return React.useContext(AuthContext);
}

export { AuthContext, AuthProvider, useAuth };