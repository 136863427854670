import { Button, Col, Row, Space, Table, Typography } from "antd";
import { memo } from "react"
import { useDispatch, useSelector } from "react-redux";
import { decreaseStep, increaseStep, setPanelData } from "../../../store/onboard/onboard-slice";
import { PANEL_TYPES } from "../../../constants/common-constants";
import { TABLE_PAGINATION_CONFIG } from "../../../constants/component-config-constants";

const PanelSelection = () => {

    const dispatch = useDispatch();

    const selectedPanel = useSelector(state => state.onboardSlice.onboardFormData.panel)
    const panelType = useSelector(state => state.onboardSlice.onboardFormData.panel.panel_type)

    const onRowSelection = (row) => {
        dispatch(setPanelData(row))
    }

    const columns = [
        {
            title: 'Panel No',
            dataIndex: 'panel_no',
            width: '30%',
            sorter: (a, b) => a.panelNo - b.panelNo,
        },
        {
            title: 'Panel Sorumlusu',
            dataIndex: 'panel_responsible',
        },
        {
            title: 'Panel Adı',
            dataIndex: 'panel_name',
            width: '40%',
        },
    ];

    const data = [];

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    const onNextClick = () => {
        dispatch(increaseStep());
    }

    const onBackClick = () => {
        dispatch(decreaseStep());
    }

    return (
        <>
            <Row align={"middle"} justify={"center"}>
                <Typography.Title level={4}>
                    {
                        panelType === PANEL_TYPES.GROUP_PANEL
                            ? 'Grubunuza Bağlı Panel Listesi'
                            : panelType === PANEL_TYPES.PERSONAL_PANEL
                                ? 'Sorumlusu Olduğunuz Panel Listesi'
                                : panelType
                    }
                </Typography.Title>
                <br/>
            </Row>
            <Row align={"middle"} justify={"center"}>
                <Col span={18}>
                    <Table
                        rowKey={"panel_no"}
                        rowSelection={{
                            type: 'radio',
                            onSelect: onRowSelection,
                            fixed: true,
                            selectedRowKeys: [selectedPanel.panel_no]
                        }}
                        pagination={TABLE_PAGINATION_CONFIG}
                        columns={columns}
                        dataSource={data}
                        onChange={onChange}
                        style={{ width: '90%' }} />
                </Col>
            </Row>
            <Row align={"middle"} justify={"center"}>
                <Space>
                    <Button onClick={onBackClick} size="large" >Geri</Button>
                    <Button onClick={onNextClick} size="large" type="primary">İleri</Button>
                </Space>

            </Row>
        </>
    )
}

export default memo(PanelSelection);