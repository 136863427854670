import { createAsyncThunk } from "@reduxjs/toolkit";
import { STORAGE_KEYS } from "../../constants/common-constants";
import { apiPost } from "../../helpers/api";
import { addPlan } from "../plan/plan-slice";

/**
 * Onboard form alanları eğer localStorage içerisinde var ise redux üzerindeki datayı bu thunk action doldurur
 */
export const setIsOnboardFormFieldsValueAvailableThunkAction = createAsyncThunk(
    'set-is-onboard-form-fields-value-available',
    (data, { getState, fulfillWithValue, rejectWithValue }) => {
        if (localStorage.getItem(STORAGE_KEYS.onboardFields)) {
            return fulfillWithValue({
                onboardFields: JSON.parse(localStorage.getItem(STORAGE_KEYS.onboardFields)),
                currentStep: Number(localStorage.getItem(STORAGE_KEYS.onboardStepCount)) || 0
            })
        }

        return rejectWithValue('NOT_AVAILABLE');
    }
)


/**
 * Plan bilgisi kayıt işlemi bu fonksiyon üzerinden eklenecek.
 */
export const savePlanThunkAction = createAsyncThunk(
    'save-plan',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiPost('/onboard', data);
            if (response.status === 201) {
                await dispatch(addPlan(response.content))
                return response;
            } 
            return rejectWithValue()
        } catch (e) {
            return rejectWithValue(e);
        }
    }
)