import { Button, Result } from "antd";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';

const NotFound = ({ title, buttonLink, buttonText, status, buttonCallback }) => {

    const navigate = useNavigate()

    const onButtonClick = () => {
        if (buttonCallback) {
            return new Promise((resolve, reject) => {
                try {
                    buttonCallback();
                    resolve(true)
                } catch (e) {
                    reject(e)
                }
            })
        } else {
            navigate(buttonLink)
        }
    }

    return (
        <Result
            status={status}
            extra={<Button type="primary" onClick={onButtonClick}>{buttonText}</Button>}
            title={title}
        />
    )
}

export default memo(NotFound);

NotFound.propTypes = {
    title: PropTypes.string,
    buttonText: PropTypes.string,
    buttonLink: PropTypes.string,
    status: PropTypes.number
}

NotFound.defaultProps = {
    title: "Sayfayı görüntülemek için giriş yapmanız gerekmektedir.",
    buttonText: "Giriş Yap",
    buttonLink: '/',
    status: 404
}