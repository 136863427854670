import { CopyOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Result, Row, Tooltip } from "antd";
import { useEffect, useMemo, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetOnboardFields, setCurrentStep } from "../../../../store/onboard/onboard-slice";
import { findPlanFromPlanLinkThunkAction } from "../../../../store/plan/plan-thunk.action";


const PlanResult = ({ }) => {

    const [isLinkCopied, setIsLinkCopied] = useState(false);
    const [lastPlan, setLastPlan] = useState(null)

    const dispatch = useDispatch();

    const plans = useSelector(state => state.planSlice.plans)
    const plan_link = useSelector(state => state.onboardSlice.onboardFormData.plan.plan_link);

    const navigate = useNavigate();

    useEffect(() => {
        if (plans) {
            setLastPlan(plans[plans.length-1])
        }
    }, [JSON.stringify(plans)])


    const copyLink = () => {
        setIsLinkCopied(true);

        setTimeout(() => {
            setIsLinkCopied(false);
        }, 500);
    }

    const goNewPlan = () => {
        dispatch(resetOnboardFields())
    }

    const goPlanDetail = () => {
        if(lastPlan) {
            navigate(`/plan/${lastPlan.id}/detail`)
        }
    }

    const createdPlanLink = useMemo(() => `${window.location.origin}/participant/participant-date-selection/${plan_link}`, [plan_link])

    return (
        <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
            <Card style={{ width: 900 }}>
                <Result
                    status="success"
                    title="Plan Oluşturuldu !"
                    subTitle="Katılımcılara davet göndermek için linki kopyalayabilir ya da yeni bir plan oluşturabilirsiniz."
                    extra={[
                        <Row align={"middle"} justify={"center"} style={{ flexBasis: "auto", marginBottom: "30px" }}>
                            <Alert type="success" message={createdPlanLink} style={{width: "500px", height: 40, lineHeight: "15px"}}/>
                            &nbsp;
                            <CopyToClipboard text={createdPlanLink}
                                onCopy={copyLink}>
                                <Tooltip title={isLinkCopied ? "Kopyalandı" : "Kopyala"} ƒ trigger={"hover"} >
                                    <Button style={{ height: 40 }}>
                                        {isLinkCopied ? "Kopyalandı" : <CopyOutlined style={{ width: "1.2em", height: "1.2em" }} />}
                                    </Button>
                                </Tooltip>
                            </CopyToClipboard>

                        </Row>,
                        <Button loading={!lastPlan} onClick={goPlanDetail} type="primary" key="detail">
                            Detaya Git
                        </Button>,
                        <Button onClick={goNewPlan} key="newPlan">Yeni Plan</Button>,
                    ]}
                />
            </Card>
        </Row>
    )

}

export default PlanResult;