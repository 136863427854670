import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiDelete, apiGet, apiPost, apiPut } from "../../helpers/api";

export const findAllPlansThunkAction = createAsyncThunk(
    'plan/find-all',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            let query;
            let apiUrl = '/plan'
            if (data && typeof data === 'object') {
                query = data.join()
                apiUrl = `${apiUrl}?relation=${query}`
            }
            const response = await apiGet(apiUrl);
            return fulfillWithValue(response);
        } catch (e) {
            return rejectWithValue([])
        }
    }
)

export const findOnePlanThunkAction = createAsyncThunk(
    'plan/find-one',
    async ({ id }, { fulfillWithValue, rejectWithValue }) => {
        try {
            const foundPlan = await apiGet(`plan/${id}`);
            if (foundPlan.status === 200) {
                return fulfillWithValue(foundPlan);
            } else {
                return rejectWithValue(null);
            }
        } catch (e) {
            console.log('Failed fetch', e);
            return rejectWithValue(null);
        }
    }
)

export const createLinkForPlanThunkAction = createAsyncThunk(
    'plan/create-link',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await apiPost('plan/create-link', data);
            return fulfillWithValue(response);
        } catch (e) {
            console.log('While creating a link, we have an error -> ', e);
            return rejectWithValue(null)
        }
    }
)

export const findPlanFromPlanLinkThunkAction = createAsyncThunk(
    'plan/find-a-plan',
    async ({ plan_link }, { rejectWithValue, fulfillWithValue, getState }) => {
        try {
            const response = await apiGet(`/plan/find-a-plan/${plan_link}`);
            if (response.status === 200) {
                const currentParticipant = getState().participantSlice.participantAuth.currentParticipant;
                if (response.content.is_anonymous && currentParticipant) {
                    const filteredParticipant = JSON.parse(JSON.stringify(response.content.participants)).filter(content => content.id === currentParticipant.id)
                    response.content.participants = filteredParticipant;
                    return response.content;
                }
                return fulfillWithValue(response.content);
            } else {
                return rejectWithValue(null)
            }
        } catch (e) {
            return rejectWithValue(null)
        }
    }
)

export const deletePlanThunkAction = createAsyncThunk(
    'plan/delete',
    async ({ id }, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await apiDelete(`/plan/${id}`);
            return fulfillWithValue(response)
        } catch (e) {
            return rejectWithValue();
        }
    }
)

export const updatePlanThunkAction = createAsyncThunk(
    'plan/update',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        const response = await apiPut(`/plan/${data.id}`, data);
        if (response.status === 200) {
            return fulfillWithValue(data);
        }
        return rejectWithValue();
    }
)

export const updatePlanParticipantThunkAction = createAsyncThunk(
    'plan/participant/add',
    async (data, { rejectWithValue, fulfillWithValue }) => {
        const response = await apiPost('plan/participant/add', data);
        return response;
    }
)

export const deletePlanParticipantThunkAction = createAsyncThunk(
    'plan/participant/delete',
    async (data, { rejectWithValue, fulfillWithValue }) => {
        const response = await apiPost('plan/participant/delete', data);
        return response;
    }
)

export const updateParticipantPlanDatesThunkAction = createAsyncThunk(
    'plan/plan-date/update',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        const response = await apiPost('plan/plan-date/update', data);
        return response;
    }
)

export const updatePlanParticipantPriorityThunkAction = createAsyncThunk(
    'participant/priority-update',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        const response = await apiPost('plan/participant/priority-update', data);
        return response;
    }
)

