import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setModuleHealth } from "../system-management/system-management-slice";
import { useAuth } from "../../hooks/useAuth";
import { getAllResponsible } from "./responsible-thunk-action";

const ResponsibleDataBridge = () => {

    const dispatch = useDispatch();
    const { isAuthenticated } = useAuth()

    const initialPreProcess = async () => { }

    const initialAuthPreProcess = async () => {
        await dispatch(getAllResponsible());
    }

    useEffect(() => {
        if (isAuthenticated) {
            (async () => {
                await initialAuthPreProcess();
            })()
        }
    }, [isAuthenticated])

    useEffect(() => {
        (async function () {
            await initialPreProcess();

            dispatch(setModuleHealth({
                moduleName: 'responsible',
                health: true
            }))
        })()
    }, [])

    return <></>
}

export default ResponsibleDataBridge;